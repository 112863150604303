import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMaintenancePlansAction } from 'modules/maintenance';
import { maintenancePlansFetchedSelector, maintenancePlansSelector } from 'modules/maintenance/selectors';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
}

const SelectMaintenancePlans: React.FC<Props> = ({ labelKey = 'Plan name', value, isDisabled, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const maintenancePlansFetched = useSelector(maintenancePlansFetchedSelector);
  const maintenancePlans = useSelector(maintenancePlansSelector);

  useEffect(() => {
    if (maintenancePlansFetched) return;
    setIsLoading(true);
    dispatch(fetchMaintenancePlansAction()).finally(() => setIsLoading(false));
  }, [maintenancePlansFetched, dispatch]);

  const { selectValue, selectOptions } = useMemo(() => {
    return maintenancePlans.reduce(
      (acc: any, setting: Maintenance.Plan) => {
        const item = {
          value: setting.id,
          label: `${setting.planName} (${setting.assetCategory})`,
          assetCategoryCode: setting.assetCategory,
        };
        if (item.value === value) acc.selectValue = item;
        acc.selectOptions.push(item);
        return acc;
      },
      { selectValue: null, selectOptions: [] }
    );
  }, [maintenancePlans, value]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={selectValue}
      options={selectOptions}
      isDisabled={isLoading || isDisabled}
      isLoading={isLoading}
      isClearable
    />
  );
};

export default SelectMaintenancePlans;
