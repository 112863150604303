import axios, { AxiosRequestConfig } from 'axios';
import queryString from 'query-string';
import { createAction } from 'redux-actions';
import {
  firstAvailableThemeInTheGroupSelectorFactory,
  mapStateAssetLayerFiltersSelector,
  mapStateCollapseGroupStateSelector,
  mapStateEnabledLayersSelector,
  mapStateLayerFiltersSelector,
  mapStateThemeSelector,
  rootSelector,
  settingsSelector,
  topTabsLabelKeysIndexesMapSelector,
  bottomTabsLabelKeysIndexesMapSelector,
} from 'modules/map/selectors';
import { portfolioIdSelector, isMapConfigFetchedSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import setUnifiedAssetActiveTabs from 'components/Panels/UnifiedAssetPanel/utils';
import { _mergeWith, _keyBy } from '@utiligize/shared/utils';
import { hideLayer, layerListToRecord, showLayer } from 'utils/map';
import { AssetLifeAPI, TaskStatus, TaskTypes, MapThemes } from 'constants/index';
import { mapDefaultState } from './index';
import { simulationVersionIdSelector } from 'modules/options/selectors';

// ------------------------------------
// Actions
// ------------------------------------
export const setMapStateAction = createAction<Partial<Map.Root>>('map/SET_STATE');

export const setSettingsAction = createAction<Partial<Map.Settings>>('map/SET_SETTINGS');

export const fetchConfigAction = createAction(
  'map/FETCH_CONFIG',
  async ({
    portfolioId,
    versionId,
    simulationId,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    versionId: number | null;
    simulationId: number | null;
  }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Map.LegendData> => {
      const state = getState();
      const isMapConfigFetched = isMapConfigFetchedSelector(state);
      if (isMapConfigFetched) dispatch(setLayoutAction({ isMapConfigFetched: false }));
      return AssetLifeAPI.get<Map.LegendResponse>('map/config', {
        params: {
          portfolio_id: portfolioId,
          version_id: versionId,
          simulation_id: simulationId,
        },
      })
        .then(res => res.data)
        .finally(() => dispatch(setLayoutAction({ isMapConfigFetched: true })));
    }
);

export const mapStateAction = createAction('map/MAP_STATE', async (modifier: Partial<Map.MapState>) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Map.MapState => {
    const state = getState();
    const collapseGroupState = mapStateCollapseGroupStateSelector(state);
    const map = rootSelector(state);
    const prev = map.mapState || {};
    const defaultState = Object.fromEntries(
      Object.keys(modifier).map(k => [k, mapDefaultState[k as keyof Map.MapState]?.(map)])
    );
    // Automatically open/close first available theme
    if (modifier.hasOwnProperty('themeGroup') && !modifier.hasOwnProperty('theme')) {
      modifier.theme = firstAvailableThemeInTheGroupSelectorFactory(modifier.themeGroup!)(state);
    }
    // Merge collapseGroupState with redux state
    if (modifier.hasOwnProperty('collapseGroupState')) {
      modifier.collapseGroupState = { ...collapseGroupState, ...modifier.collapseGroupState };
    }
    // Configure UAV default tabs on map theme changed
    if (modifier.hasOwnProperty('theme')) {
      const { TopTabsIndexesMap } = topTabsLabelKeysIndexesMapSelector(getState());
      const { BottomTabsIndexesMap } = bottomTabsLabelKeysIndexesMapSelector(getState());
      setUnifiedAssetActiveTabs(modifier.theme, TopTabsIndexesMap, BottomTabsIndexesMap);
    }

    // Close filters on task theme enabled event, since they are disabled
    if (modifier.theme === MapThemes.TASK) {
      modifier.collapseGroupState = { ...collapseGroupState, voltage: false, stations: false, assets: false };
    }
    return _mergeWith({}, defaultState, prev, modifier, (_, b) =>
      !Array.isArray(b) && typeof b === 'object' ? undefined : b
    );
  };
});

export const fetchMaxLoadSummaryAction = createAction(
  'map/FETCH_MAX_LOAD_SUMMARY',
  (scenarioId: Layouts.ScenarioId) => (): Promise<Map.MaxLoadSummary> => {
    return AssetLifeAPI.get('scenarios/der_description_map', { params: { scenario_id: scenarioId } }).then(
      res => res.data
    );
  }
);

export const fetchTasksFeatureCollection = createAction(
  'map/FETCH_TASKS_FEATURE_COLLECTION',
  (yearFrom: number, yearTo: number, { signal }: AxiosRequestConfig) =>
    (dispatch: Function, getState: () => State.Root): Promise<Map.Root['tasksFeatureCollection']> => {
      dispatch(setMapStateAction({ isLoading: true }));
      const state = getState();
      const portfolioId = portfolioIdSelector(state);
      const versionId = simulationVersionIdSelector(state);
      return axios
        .get('api/admin/v3/secure/map/tasks', {
          params: { yearFrom, yearTo, portfolioId: portfolioId, versionId },
          signal,
        })
        .then(res => ({
          ...res.data,
          features: res.data.features.map((feature: any) => ({
            ...feature,
            properties: {
              ...feature.properties,
              ...Object.assign(
                {},
                ...feature.properties.userEmailsStr
                  .split(',')
                  .map((i: string, index: number) => ({ [`user${index}`]: i }))
              ),
              status:
                feature.properties.type === TaskTypes.Autogenerated
                  ? feature.properties.assetCompleted
                    ? TaskStatus.Finished
                    : TaskStatus.NotStarted
                  : feature.properties.status,
            },
          })),
        }));
    }
);

export const fetchTaskFiltersAction = createAction(
  'map/FETCH_TASK_FILTERS',
  async () => (): Promise<Map.TaskFilters | null> =>
    axios.get<Map.TaskFilters>('api/admin/v1/secure/map/filters').then(res => res.data)
);

export const setMapLayersAction = createAction<Map.StyleLayer[] | null>('map/SET_MAP_LAYERS');

export const setDataQualityWarningAction = createAction<Map.DataQualityWarning | null>('map/SET_DATA_QUALITY_WARNING');

export const fetchPopupAssetInfoAction = createAction(
  'map/FETCH_POPUP_ASSET_INFO',
  (uuid: string) =>
    (dispatch: Function, getState: () => State.Root): Promise<Map.FetchFindAssetData> => {
      const state = getState();
      const simulationId = simulationIdSelector(state);
      const versionId = simulationVersionIdSelector(state);
      return (
        axios
          .get<Map.FetchFindAssetData>(`api/admin/v1/secure/map/find_asset/${uuid}`, {
            params: { version_id: versionId, simulation_id: simulationId },
          })
          // check layersState later for found layer to allow not only assets
          .then(res => res.data)
      );
    }
);

export const fetchN1RouteAction = createAction(
  'map/FETCH_N1_ROUTE',
  (params: { asset_uuid: string; md5sum?: string; md5sum_normal?: string }) => {
    return (dispatch: Function, getState: () => State.Root): Promise<Map.N1Route | null> => {
      const state = getState();
      const simulationId = simulationIdSelector(state);
      const versionId = simulationVersionIdSelector(state);
      return AssetLifeAPI.get<Map.N1Route>('map/n-1/route', {
        params: { ...params, version_id: versionId, simulation_id: simulationId },
      })
        .then(res => res.data || null)
        .catch(() => null);
    };
  }
);

export const fetchDataQualityWarningAction = createAction(
  'map/FETCH_DATA_QUALITY_WARNING',
  (params: Pick<DataQuality.Issue, 'issue_id' | 'code'>, warning: DataQuality.Issue['warning']) => {
    return (dispatch: Function, getState: () => State.Root): Promise<Map.DataQualityWarning | null> => {
      const state = getState();
      const simulationId = simulationIdSelector(state);
      const versionId = simulationVersionIdSelector(state);
      return AssetLifeAPI.get<Map.DataQualityWarning>('map/data_quality_warning', {
        params: {
          md5sum: params.issue_id,
          code: params.code,
          version_id: versionId,
          simulation_id: simulationId,
        },
      })
        .then(res => (res.data ? { ...res.data, description: warning } : null))
        .catch(() => null);
    };
  }
);

export const fetchMapInvestmentScenariosAction = createAction(
  'map/FETCH_INVESTMENT_SCENARIOS',
  async (scenario_id: Layouts.ScenarioId) => (): Promise<Type.SelectOption[]> =>
    AssetLifeAPI.get<Type.SelectOption[]>('map/investment_scenarios', { params: { scenario_id } }).then(res => res.data)
);

export const setAddressSearchAction = createAction('map/SET_ADDRESS_SEARCH');

export const setLabelsAction = createAction<Map.Root['showLabels']>('map/SET_LABELS');

const CnaimIdIconsMap: { [key: number]: string } = {
  7: 'switch',
  18: 'switch',
  29: 'two_circles',
  40: 'line',
  46: 'line',
  47: 'line',
  53: 'line',
  63: 'small_circle',
  72: 'switch',
  79: 'two_circles',
  94: 'triangle',
  95: 'triangle',
  100: 'line',
  111: 'small_circle',
  112: 'small_circle',
};

export const fetchSingleLineDiagramAction = createAction(
  'map/FETCH_SINGLE_LINE_DIAGRAM',
  ({
    type,
    portfolioId,
    primary_substation_id,
    versionId,
    singleLineDiagramFilterVoltagesIds,
  }: {
    type: SingleLineDiagram.Types;
    portfolioId: Layouts.Root['portfolioId'];
    primary_substation_id: number;
    versionId: number;
    singleLineDiagramFilterVoltagesIds: Layouts.Root['singleLineDiagramFilterVoltagesIds'];
  }) =>
    (dispatch: Function): Promise<any> => {
      return AssetLifeAPI.get('single_line_diagram/topology', {
        params: {
          type,
          portfolio_id: portfolioId,
          primary_substation_id,
          search: '',
          version_id: versionId,
          voltage_id: singleLineDiagramFilterVoltagesIds,
          in_operation_check: false,
          simplified_graph: false,
          filter_by_station_id: false,
        },
        paramsSerializer: params => queryString.stringify(params),
      }).then(res => {
        if (!res.data.vertices) return;

        const CnaimLookupHash = _keyBy(res.data.cnaim_look_up, (item: SingleLineDiagram.CnaimLookup) => item.id);
        const VoltagesUsedHash = _keyBy(res.data.voltages_used, (item: SingleLineDiagram.VoltagesUsed) => item.id);

        const { vertices, vertices_names } = res.data.vertices.reduce(
          (acc: any, v: SingleLineDiagram.Vertice, index: number) => {
            acc.vertices.push({
              ...v,
              voltage_id: VoltagesUsedHash[v.voltage_id].voltage_id,
              voltage_level_text: VoltagesUsedHash[v.voltage_id].voltage_level_text,
              asset_icon_name: CnaimIdIconsMap[v.cnaim_id],
              asset_register_category: CnaimLookupHash[v.cnaim_id].asset_register_category,
            });

            acc.vertices_names.push(v.name);

            return acc;
          },
          { vertices: [], vertices_names: [] }
        );

        const edges = res.data.edges.map((e: SingleLineDiagram.Edge) => ({
          ...e,
          asset_icon_name: CnaimIdIconsMap[e.cnaim_id],
          asset_register_category: CnaimLookupHash[e.cnaim_id].asset_register_category,
        }));

        return { ...res.data, vertices, edges, vertices_names };
      });
    }
);

export const setMapDrawAssetFeaturesAction = createAction('map/SET_MAP_DRAW_ASSET_FEATURES');

export const updateMapDrawAssetFeaturePropertiesAction = createAction('map/UPDATE_MAP_DRAW_ASSET_FEATURE_PROPERTIES');

export const changeFilterVoltagesAction = createAction(
  'map/CHANGE_FILTER_VOLTAGES',
  async (isChecked: boolean, voltage_level_id: number, map: Map.MapboxMap | null) =>
    (dispatch: Function, getState: () => State.Root): Promise<Map.MapState> => {
      const state = getState();
      const settings = settingsSelector(state);
      const theme = mapStateThemeSelector(state);
      const mapStateLayerFilters = mapStateLayerFiltersSelector(state);
      const mapStateAssetLayerFilters = mapStateAssetLayerFiltersSelector(state);
      const enabledLayers = mapStateEnabledLayersSelector(state);

      const filteredLayers = Object.keys(mapStateAssetLayerFilters)
        .map(k => {
          const filter = mapStateAssetLayerFilters[k];
          if (!settings.assetLayers?.some(i => i.startsWith(k))) return null;
          if (!isChecked) return filter?.list.includes(voltage_level_id) ? k : null;
          return filter?.initList.some(i => i.id === voltage_level_id) ? k : null;
        })
        .filter(Boolean) as string[];

      const newState = { layerFilters: { ...mapStateLayerFilters } } as Partial<Map.MapState>;
      const layers = [] as string[];
      const action = isChecked ? showLayer : hideLayer;

      for (const layer of filteredLayers) {
        const prev = mapStateAssetLayerFilters[layer];
        const prevList = mapStateAssetLayerFilters[layer]?.list ?? [];
        const checkedItems = isChecked ? [...prevList, voltage_level_id] : prevList.filter(i => i !== voltage_level_id);
        const filter = { ...prev, list: checkedItems } as Map.LayerFilter;
        const isEnabled = Object.entries(enabledLayers ?? {}).some(([k, v]) => k.startsWith(layer) && v);
        newState.layerFilters![layer] = filter;
        if ((isChecked && !isEnabled && filter.list.length) || (!isChecked && isEnabled && !filter.list.length)) {
          layers.push(...settings.assetLayers!.filter(i => i.startsWith(layer)));
        }
      }

      if (layers.length) {
        newState.enabledLayers = { ...enabledLayers, ...layerListToRecord(layers, isChecked) };
      }

      if (!theme && map) layers.forEach(i => action(map, i));
      return dispatch(mapStateAction(newState));
    }
);
