import { createSelector } from 'reselect';
import { featureTogglesSelector, singleDiagramEnabledSelector } from 'modules/layouts/selectors';
import { appCurrentUserPermissionsSelector, isMaintenancePlanningEnabledSelector } from 'modules/app/selectors';
import { _pickBy } from '@utiligize/shared/utils';
import { mapDefaultState } from './index';
import { isNKTenant, isNordEnergiTenant } from 'utils';
import { MapThemes, ThemeDisabledSettingsKey, MapThemeGroupsConfig, StorageKeys } from 'constants/index';

// ------------------------------------
// Selectors
// ------------------------------------

export const rootSelector = (state: State.Root) => state.map;

export const mapIsLoadingSelector = createSelector(rootSelector, map => map.isLoading);

export const legendSelector = createSelector(rootSelector, map => map.legendData);

export const mapStateSelector = createSelector(rootSelector, map => map.mapState);

export const mapStateThemeGroupSelector = createSelector(mapStateSelector, mapState => mapState.themeGroup || null);

export const mapStateThemeSelector = createSelector(mapStateSelector, mapState => mapState?.theme || null);

export const mapStateCollapseGroupStateSelector = createSelector(
  mapStateSelector,
  mapState => mapState?.collapseGroupState || null
);

export const mapStateLayerFiltersSelector = createSelector(mapStateSelector, mapState => mapState?.layerFilters || {});

export const mapStateAssetLayerFiltersSelector = createSelector(mapStateLayerFiltersSelector, layerFilters =>
  Object.keys(layerFilters).reduce(
    (acc, k) => {
      if (k.startsWith('asset__')) acc[k] = layerFilters[k];
      return acc;
    },
    {} as Record<string, Map.LayerFilter>
  )
);

// {[ layerId: true | false ]}
export const mapStateEnabledLayersSelector = createSelector(mapStateSelector, mapState => mapState?.enabledLayers);

export const mapStateSatelliteVisibilitySelector = createSelector(
  mapStateSelector,
  mapState => mapState?.satelliteVisibility
);

export const mapStateBuildingsVisibilitySelector = createSelector(
  mapStateSelector,
  mapState => mapState?.buildingsVisibility
);

export const mapStateHeatmapScenarioSelector = createSelector(mapStateSelector, mapState => mapState?.heatmapScenario);

export const enabledLayersListSelector = createSelector(mapStateEnabledLayersSelector, enabledLayers =>
  Object.keys(_pickBy(enabledLayers || {}))
);

export const settingsSelector = createSelector(rootSelector, map => map.settings);

export const mapLayersSelector = createSelector([rootSelector, featureTogglesSelector], (map, featureToggles) => {
  const layer = map.mapLayers?.find(k => k.legend?.id === 'other__customers_der');
  if (layer?.legend?.filters?.list) {
    layer.legend.filters.list = layer.legend.filters.list.filter(
      filter =>
        !isNordEnergiTenant || featureToggles[StorageKeys.FEATURE_MAP_DER_AI_THEMES] || !filter.name.includes('(AI)')
    );
  }
  return map.mapLayers;
});

export const dataQualityWarningSelector = createSelector(rootSelector, map => map.dataQualityWarning);

export const mapStateDataQualityFiltersSelector = createSelector(
  mapStateSelector,
  mapState => mapState.dataQualityFilters
);

export const n1RouteSelector = createSelector(rootSelector, map => map.n1Route);

export const selectedN1RoutesIdsSelector = createSelector(rootSelector, map => map.selectedN1RoutesIds);

export const selectedN1RoutesSelector = createSelector(
  [n1RouteSelector, selectedN1RoutesIdsSelector],
  (n1Route, selectedN1RoutesIds) => n1Route?.routes?.filter(route => selectedN1RoutesIds.includes(route.id)) || []
);

export const highlightedN1RouteIdSelector = createSelector(rootSelector, map => map.highlightedN1RouteId);

export const showAddressSearchSelector = createSelector(rootSelector, map => map.showAddressSearch);

export const showCableAnimationSelector = createSelector(rootSelector, map => map.showCableAnimation);

export const showLabelsSelector = createSelector(rootSelector, map => map.showLabels);

export const createMapStateSelectorFactory = <K extends keyof Map.MapState>(prop: K) => {
  return createSelector(rootSelector, (map: Map.Root): Map.MapState[K] => {
    const state = map.mapState;
    return state?.[prop] ?? (mapDefaultState[prop]?.(map) as Map.MapState[K]);
  });
};

export const mapVoltageFiltersListSelector = createSelector(
  [mapStateAssetLayerFiltersSelector, settingsSelector],
  (mapStateAssetLayerFilters, settings) => {
    const getFilterState = (id: number | string) => {
      return Object.keys(mapStateAssetLayerFilters).reduce(
        (acc, key: string) => {
          if (!acc.isChecked) {
            acc.isChecked = mapStateAssetLayerFilters[key]?.list.includes(id);
          }
          if (acc.isDisabled) {
            acc.isDisabled = !mapStateAssetLayerFilters[key]?.initList.some(i => i.id === id);
          }
          return acc;
        },
        { isChecked: false, isDisabled: true }
      );
    };
    return (
      settings?.globalFilters?.filter_voltage?.list.map(filter => ({ ...filter, ...getFilterState(filter.id) })) || []
    );
  }
);

export const mapVoltageCheckedFiltersSelector = createSelector(mapVoltageFiltersListSelector, mapVoltageFiltersList => {
  return mapVoltageFiltersList.filter(filter => filter.isChecked);
});

export const primarySubstationsListSelector = createSelector(
  [settingsSelector, singleDiagramEnabledSelector],
  (settings, singleDiagramEnabled): Map.StyleLayerFilterItem[] => {
    const list = settings?.globalFilters?.filter_primary_substations?.list;
    if (list && singleDiagramEnabled) return list.filter(option => option.name !== '150_top_node');
    return list || [];
  }
);

export const bottomTabsChartsNextYearValueSelector = createSelector(mapStateSelector, (mapState): number | null => {
  if (mapState.theme === MapThemes.YEARLY_MAX_LOAD) return mapState.scenario?.year || null;
  if (mapState.theme === MapThemes.YEARLY_CONSUMPTION) return mapState.consumptionScenario?.year || null;
  if (mapState.theme === MapThemes.YEARLY_LOSSES) return mapState.lossesScenario?.year || null;
  return null;
});

export const otherLegendSelector = createSelector(
  [legendSelector, settingsSelector, enabledLayersListSelector, mapLayersSelector],
  (legend, settings, enabledLayersList, mapLayers) => {
    const checkedFilters = [
      ...new Set(
        enabledLayersList
          .filter(id => settings.otherLayers!.includes(id))
          .map(id => settings.layerTitle?.[id])
          .filter(Boolean)
      ),
    ];

    return { legend: legend.other, checkedFilters, disabled: settings.isOtherGroupDisabled, mapLayers };
  }
);

export const cnaimLegendSelector = createSelector(
  [legendSelector, settingsSelector, enabledLayersListSelector, mapLayersSelector],
  (legend, settings, enabledLayersList, mapLayers) => {
    const checkedFilters = [
      ...new Set(
        enabledLayersList
          .filter(id => settings.cnaimLayers!.includes(id))
          .map(id => settings.layerTitle?.[id])
          .filter(Boolean)
      ),
    ];

    return { legend: legend.cnaim, checkedFilters, disabled: settings.isCNAIMGroupDisabled, mapLayers };
  }
);

export const firstAvailableThemeInTheGroupSelectorFactory = (themeGroup: Map.ThemeGroups | null) =>
  createSelector(settingsSelector, settings => {
    if (!themeGroup) return null;
    const activeThemes = (themeGroup ? MapThemeGroupsConfig[themeGroup] : []).filter(
      t => !settings[ThemeDisabledSettingsKey[t]]
    );
    return activeThemes[0] || null;
  });

export const mapDrawAssetFeaturesSelector = createSelector(rootSelector, map => map.mapDrawAssetFeatures);

export const mapDrawAssetFeatureSelectorFactory = (id: string | null) =>
  createSelector(mapDrawAssetFeaturesSelector, mapDrawAssetFeatures =>
    mapDrawAssetFeatures?.find(feature => feature.id === id)
  );

export const topTabsLabelKeysIndexesMapSelector = createSelector(
  [isMaintenancePlanningEnabledSelector, appCurrentUserPermissionsSelector],
  (isMaintenancePlanningEnabled, appCurrentUserPermissions) => {
    const TopTabsMap = new Map();

    if (isNKTenant) TopTabsMap.set('LiveData', 'Live data');
    TopTabsMap.set('AssetDetails', 'Asset details');
    TopTabsMap.set('Reinvestment', 'Reinvestment');

    if (isMaintenancePlanningEnabled) {
      TopTabsMap.set('SubComponents', 'Sub-components');
      TopTabsMap.set('Photo', 'Photo');
    }

    if (appCurrentUserPermissions.isHistoryEnabled) {
      TopTabsMap.set('History', 'History');
      TopTabsMap.set('Answers', 'Answers');
    }

    if (appCurrentUserPermissions.isTasksEnabled) {
      TopTabsMap.set('TaskCompletions', 'Task completions');
    }

    if (appCurrentUserPermissions.isDataManualsEnabled) {
      TopTabsMap.set('DataManuals', 'Data manuals');
    }

    if (appCurrentUserPermissions.isCustomersEnabled) {
      TopTabsMap.set('Customers', 'Customers');
    }

    TopTabsMap.set('Comments', 'Comments');

    const TopTabsLabelKeys = [...TopTabsMap.values()];
    const TopTabsIndexesMap = Object.fromEntries(Array.from(TopTabsMap.keys()).map((key, index) => [key, index]));

    return { TopTabsLabelKeys, TopTabsIndexesMap };
  }
);

export const bottomTabsLabelKeysIndexesMapSelector = createSelector(
  [isMaintenancePlanningEnabledSelector, appCurrentUserPermissionsSelector],
  (isMaintenancePlanningEnabled, appCurrentUserPermissions) => {
    const BottomTabsMap = new Map();

    BottomTabsMap.set('Power', 'Power');
    BottomTabsMap.set('Voltage', 'Voltage');
    BottomTabsMap.set('Losses', 'Losses');
    BottomTabsMap.set('Evolution', 'Evolution');
    BottomTabsMap.set('Profile', 'Profile');
    BottomTabsMap.set('Duration', 'Duration');
    BottomTabsMap.set('Reliability', 'Reliability');

    if (appCurrentUserPermissions.isTasksEnabled) {
      BottomTabsMap.set('Tasks', 'Tasks');
    }

    const BottomTabsLabelKeys = [...BottomTabsMap.values()];
    const BottomTabsIndexesMap = Object.fromEntries(Array.from(BottomTabsMap.keys()).map((key, index) => [key, index]));

    return { BottomTabsLabelKeys, BottomTabsIndexesMap };
  }
);
