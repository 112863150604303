import styled from 'styled-components';
import queryString from 'query-string';
import React, { useRef, useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getTimeSeriesChartData } from 'modules/networkLoading';
import { portfolioIdSelector, scenarioIdSelector, selectedChartFlexSelector } from 'modules/layouts/selectors';
import { selectedAndExistChartYearSelector } from 'modules/options/selectors';
import { setLayoutAction } from 'modules/layouts';
import { SelectScenarioYears, ButtonDownload, Checkbox } from 'components/_common';
import Chart, { Colors, ColorsMap, getBaseOptions } from './Chart';
import { TimeSeriesTypes } from 'constants/index';

interface ChartDataProps {
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
  plotLines: Shared.XAxisPlotLinesOptions[];
  series: Shared.SeriesOptionsType[];
  noDataMessage: string;
}

const medianLineID = 'median-line';

const KeysColorsMap: { [key: string]: string } = {
  kwh: ColorsMap.LIGHT_BLUE,
  kwh_prod: ColorsMap.LIGHT_GREEN,
  kvar: ColorsMap.LAVENDER_BLUE,
  kva: ColorsMap.LAVENDER_BLUE, // kva - the same as kvar
  kvar_prod: ColorsMap.LIGHT_ORANGE,
  kva_prod: ColorsMap.LIGHT_ORANGE, // kva_prod - the same as kvar_prod
};

const transformChartData = (action: Shared.ReduxAction<any>): ChartDataProps | null => {
  if (!Object.keys(action.payload || {}).length) return null;

  const filterKeys = (keys: string[]) => keys.filter(key => !['time', 'tenant_id'].includes(key));

  const seriesHash = action.payload.ts_data?.reduce((acc: any, item: any) => {
    const keys = filterKeys(Object.keys(action.payload.ts_data[0] || {}));
    const isStacked = action.payload.chart_type === 'column';
    keys.forEach((key, index) => {
      const elem = [item.time * 1000, Number(item[key])];
      // Add Median line for stacked chart
      if (isStacked) {
        const medianLineElem = [item.time * 1000, Number(keys.reduce((acc, key) => acc + item[key], 0))];
        if (!acc[medianLineID]) {
          acc[medianLineID] = {
            id: medianLineID,
            name: 'Sum',
            type: 'line',
            data: [medianLineElem],
            color: '#000',
            index: 0,
            lineWidth: 0.5,
            visible: false,
          };
        } else {
          acc[medianLineID].data.push(medianLineElem);
        }
      }
      if (!acc[key]) {
        acc[key] = {
          name: key,
          type: action.payload.chart_type || 'line',
          data: [elem],
          color: KeysColorsMap[key] || Colors[index],
          index: action.payload.z_index?.[key] || index + 1,
          lineWidth: 0.5,
        };
      } else {
        acc[key].data.push(elem);
      }
    });
    return acc;
  }, {});

  const seriesLineHash = action.payload.ts_data_line?.reduce((acc: any, item: any) => {
    const keys = filterKeys(Object.keys(action.payload.ts_data_line[0] || {}));
    keys.forEach(key => {
      const elem = [item.time * 1000, item[key]];
      if (!acc[key]) {
        acc[key] = {
          name: key,
          type: 'line',
          data: [elem],
          color: Colors[4],
          index: 0,
          lineWidth: 0.5,
        };
      } else {
        acc[key].data.push(elem);
      }
    });
    return acc;
  }, {});

  const plotLines = (() => {
    const line = action.payload.horizontal_line;
    if (!line) return [];
    return [line, -line].map(value => ({
      color: '#c77cff',
      width: 2,
      zIndex: 5,
      value,
      label: {
        text: 'Rating',
        style: { color: '#c77cff', fontWeight: 'bold' },
      },
    }));
  })();

  return {
    title: action.payload.title,
    xAxisTitle: action.payload.xlabel,
    yAxisTitle: action.payload.ylabel,
    plotLines,
    series: Object.values<Shared.SeriesOptionsType>(seriesHash || {}).concat(
      Object.values<Shared.SeriesOptionsType>(seriesLineHash || {})
    ),
    noDataMessage: action.payload.no_data_message || '',
  };
};

interface Props {
  uuid: string;
  type: TimeSeriesTypes;
  height?: '100%';
  isInCustomerRightsProfileModal?: boolean;
  isTooltipOutside?: boolean;
}

const AssetTimeSeriesChart: React.FC<Props> = ({
  uuid,
  type,
  height,
  isInCustomerRightsProfileModal = false,
  isTooltipOutside = false,
}) => {
  const { getIntl, lng } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const year = useSelector(selectedAndExistChartYearSelector);
  const flex = useSelector(selectedChartFlexSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const isInfoMessageVisible = type === TimeSeriesTypes.POWER;

  const textRef = useRef<HTMLDivElement>(null);
  const [textHeight, setTextHeight] = useState(0);

  useEffect(() => {
    if (!textRef.current) return;
    setTextHeight(textRef.current.offsetHeight);
  }, [chartData]);

  const handleSelectYear = useCallback(
    (option: Type.SelectOption<number>) => dispatch(setLayoutAction({ selectedChartYear: option?.value })),
    [dispatch]
  );

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      dispatch(setLayoutAction({ selectedChartFlex: event.currentTarget.checked })),
    [dispatch]
  );

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !scenarioId || !year) return;
    dispatch(getTimeSeriesChartData({ portfolioId, scenarioId, uuid, type, year, flex }))
      .then(transformChartData)
      .then(setChartData)
      .catch(() => setChartData({ series: [] } as any));
  }, [dispatch, portfolioId, scenarioId, uuid, type, year, flex]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);
    return {
      ...baseOptions,
      chart: {
        ...baseOptions.chart,
        events: {
          render: () => {
            if (!textRef.current) return;
            setTextHeight(textRef.current.offsetHeight);
          },
        },
      },
      plotOptions: {
        ...baseOptions.plotOptions,
        column: {
          stacking: 'normal',
        },
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d %H:%M',
        crosshairs: {
          color: 'green',
          dashStyle: 'solid',
        },
        shared: true,
        outside: isTooltipOutside,
      },
      yAxis: {
        ...baseOptions.yAxis,
        plotLines: chartData?.plotLines?.map(p => ({
          ...p,
          label: { ...p.label, text: getIntl(p.label?.text || '') },
        })),
      },
      xAxis: { ...baseOptions.xAxis, type: 'datetime' },
      series:
        chartData?.series.map(s => ({
          ...s,
          name: getIntl(s.name || ''),
          events: {
            legendItemClick: function (this: Highcharts.Series) {
              const medianLineSeries = this.chart.series.find(i => i.options.id === medianLineID);
              if (!medianLineSeries) return;

              const visibleSeries = this.chart.series.filter(i => medianLineID !== i.options.id && i.visible);
              const visibleSeriesData = visibleSeries.map(i => (i.options as any).data);
              if (!visibleSeriesData?.length) return medianLineSeries.setData([]);

              const newMedianLineData = visibleSeriesData[0].map(([time]: [number], index: number) => [
                time,
                visibleSeriesData.reduce((acc, i) => acc + i[index][1], 0),
              ]);
              medianLineSeries.setData(newMedianLineData);
            },
          },
        })) || [],
      ...(chartData?.noDataMessage
        ? {
            lang: {
              noData: getIntl(chartData.noDataMessage),
            },
          }
        : null),
      ...(isInfoMessageVisible
        ? {
            legend: {
              y: -textHeight + 10,
            },
          }
        : null),
    };
  }, [chartData, getIntl, isTooltipOutside, textHeight, isInfoMessageVisible]) as unknown as Highcharts.Options;

  return (
    <>
      <StyledContainer data-marker="asset_timeseries__buttons_block">
        <SelectScenarioYears
          labelKey=""
          value={year}
          onChange={handleSelectYear}
          variant="small"
          isSearchable={false}
          isInCustomerRightsProfileModal={isInCustomerRightsProfileModal}
        />
        <ButtonDownload
          dataMarker="download_timeseries_excel"
          tooltipKey="Download time series"
          fileNameLocaleStr="Time series"
          link={`load/asset_class_timeseries_excel?${queryString.stringify({
            portfolio_id: portfolioId,
            scenario_id: scenarioId,
            lang: lng.toLowerCase(),
            id: uuid,
            type,
            year,
          })}`}
          className="mx-2"
        />
        {!isInCustomerRightsProfileModal && (
          <Checkbox
            labelKey="Flex"
            className="icheck-primary"
            name="flex"
            checked={flex}
            onChange={handleCheckboxClick}
          />
        )}
      </StyledContainer>

      <Chart dataMarker="asset_timeseries_chart" options={chartData ? options : null} height={height} />
      {Boolean(chartData) && isInfoMessageVisible && (
        <StyledText ref={textRef}>
          {getIntl(
            year! > new Date().getFullYear()
              ? 'Deviations between yearly max load and timeseries values can be explained by differences in the calculation method (AC vs. DC load flow) or in the percentile selected (100th percentile is shown on the timeseries)'
              : 'Deviations between yearly max load and timeseries values can be explained by the percentile selected (100th percentile is shown on the timeseries)'
          )}
        </StyledText>
      )}
    </>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 32px;
  right: 50%;
  transform: translate(50%);
  display: inline-flex;
  justify-content: space-between;
`;

const StyledText = styled.small`
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  padding: 0 0.5rem;
`;

export default AssetTimeSeriesChart;
