import moment from 'moment';
import queryString from 'query-string';
import { createAction } from 'redux-actions';
import { appLangSelector } from 'modules/app/selectors';
import {
  paginationSelectorFactory,
  portfolioIdSelector,
  profileModalMetaSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { futureChartDataHashSelector, scenarioRunInfoSelector } from 'modules/networkLoading/selectors';
import { setSuccessToastAction } from 'modules/layouts';
import { fetchRightsAction } from 'modules/customers';
import {
  portfolioOptionsSelector,
  scenarioOptionsHashSelector,
  simulationOptionsHashSelector,
  simulationVersionIdSelector,
} from 'modules/options/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { getStorageItem } from 'utils';
import { PaginationType, AssetLifeAPI, StorageKeys, TimeSeriesTypes } from 'constants/index';
import { fetchCablesAction } from './cables.actions';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchTransformersAction: any = createAction(
  'network-loading/FETCH_TRANSFORMERS',
  async ({ skipPagination, skipStoreUpdate } = { skipPagination: false, skipStoreUpdate: undefined }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'transformersHash' | 'transformersCount'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.TRANSFORMERS)(
        state
      );
      return AssetLifeAPI.get('load/load_transformers', {
        params: {
          limit: skipPagination ? undefined : limit,
          offset: skipPagination ? 0 : offset,
          sort,
          column,
          query,
          portfolio_id: portfolioIdSelector(state),
          scenario_id: scenarioIdSelector(state),
          simulation_id: simulationIdSelector(state),
          version_id: simulationVersionIdSelector(state),
          hide_solved: filters?.solvedValue,
          voltage: filters?.voltage,
          year: filters?.year,
          voltage_side: filters?.voltageSide,
          voltage_display: filters?.voltageDisplay,
          simple_sums: filters?.BICalculation,
          percentile: filters?.percentile,
          flex: filters?.flex,
        },
      }).then((res: any) => ({
        transformersCount: res.data.count,
        transformersHash: _keyBy(res.data.rows, (item: NetworkLoading.Transformer) => item.id),
        skipStoreUpdate,
      }));
    }
);

export const fetchAssetCommentsAction: any = createAction(
  'network-loading/FETCH_COMMENTS',
  async ({
    uuid,
    portfolioId,
    scenarioId,
  }: {
    uuid: string;
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'ignoreMessagesHash' | 'ignoreMessagesCount'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query } = paginationSelectorFactory(
        PaginationType.TRANSFORMERS_IGNORE_MESSAGES
      )(state);
      return AssetLifeAPI.get('load/loading_ignore_messages', {
        params: { id: uuid, limit, offset, sort, column, query, portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then((res: any) => ({
        ignoreMessagesCount: res.data.count,
        ignoreMessagesHash: _keyBy(res.data.rows, (item: NetworkLoading.IgnoreMessages) => `_${item.id}_`),
      }));
    }
);

export const setSolvedAction = createAction(
  'network-loading/SET_SOLVED',
  async (uuid: string, explanation: string, solved: boolean, paginationType: Type.PaginationType) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      const claims: Users.Claims | null = getStorageItem(StorageKeys.USER_CLAIMS);
      const state: State.Root = getState();

      const ActionMap: { [key in Type.PaginationType]?: { url: string; postRequestAction: any } } = {
        [PaginationType.TRANSFORMERS]: {
          url: 'load/load_transformers',
          postRequestAction: fetchTransformersAction,
        },
        [PaginationType.CABLES]: {
          url: 'load/load_cables',
          postRequestAction: fetchCablesAction,
        },
        [PaginationType.CUSTOMERS_RIGHTS]: {
          url: 'customers/customer_rights',
          postRequestAction: fetchRightsAction,
        },
      };

      return AssetLifeAPI.put(`/${ActionMap[paginationType]?.url}/${uuid}`, {
        username: claims ? `${claims.firstName} ${claims.lastName} (${claims.email})` : '',
        check: solved,
        explanation,
        portfolio_id: portfolioIdSelector(state),
        scenario_id: scenarioIdSelector(state),
      }).then(async (res: any) => {
        await dispatch(ActionMap[paginationType]?.postRequestAction());
        dispatch(setSuccessToastAction('Action has been saved'));
      });
    }
);

enum APIs {
  power = '/load/asset_timeseries_power',
  voltage = '/load/asset_timeseries_voltage',
  losses = '/load/asset_timeseries_losses',
}

export const getTimeSeriesChartData = createAction(
  'network-loading/GET_TIME_SERIES_CHART_DATA',
  ({
    portfolioId,
    scenarioId,
    uuid,
    type,
    year,
    flex,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    uuid: string;
    type: TimeSeriesTypes;
    year: number | null;
    flex: boolean;
  }) =>
    (): Promise<any> =>
      AssetLifeAPI.get(APIs[type], {
        params: {
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          id: uuid,
          year,
          flex,
        },
      }).then(res => res.data)
);

export const getProfileChartData = createAction(
  'network-loading/GET_PROFILE_CHART_DATA',
  ({
    portfolioId,
    scenarioId,
    uuid,
    year,
    flex,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    uuid: string;
    year: number | null;
    flex: boolean;
  }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<any> => {
      const state = getState();
      return AssetLifeAPI.get(
        `/load/load_asset_profile?${queryString.stringify({
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          lang: appLangSelector(state).toLowerCase(),
          id: uuid,
          year,
          flex,
        })}`
      ).then(res => res.data);
    }
);

export const getDurationChartData = createAction(
  'network-loading/GET_DURATION_CHART_DATA',
  ({
    portfolioId,
    scenarioId,
    uuid,
    year,
    flex,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    uuid: string;
    year: number | null;
    flex: boolean;
  }) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<any> => {
      const state = getState();
      return AssetLifeAPI.get(
        `/load/load_asset_duration?${queryString.stringify({
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          lang: appLangSelector(state).toLowerCase(),
          id: uuid,
          year,
          flex,
        })}`
      ).then(res => res.data);
    }
);

export const fetchAssetCustomersAction: any = createAction(
  'network-loading/FETCH_ASSET_CUSTOMERS',
  async ({
    uuid,
    portfolioId,
    scenarioId,
    year,
  }: {
    uuid?: string | null;
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    year: Layouts.Root['selectedChartYear'];
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'customersTypesHash' | 'customersTypesInstallations'>> => {
      const state: State.Root = getState();
      const meta = profileModalMetaSelector(state);
      return AssetLifeAPI.get('customers/customer_types_uuid/', {
        params: {
          id: uuid || meta?.id || null,
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          year,
        },
      }).then((res: any) => ({
        customersTypesHash: _keyBy(res.data.summary, (item: NetworkLoading.CustomersTypes) => `_${item.id}_`),
        customersTypesInstallations: res.data.installations,
      }));
    }
);

export const getTotalLoadingAggregatedMeterChartsDataAction = createAction(
  'network-loading/GET_TOTAL_LOADING_AGGREGATED_METER_CHARTS_DATA',
  ({
    portfolioId,
    scenarioId,
    startDate,
    endDate,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    startDate: string | null;
    endDate: string | null;
  }) =>
    (): Promise<Omit<Shared.ChartAPIResponse, 'data'>[] | null> => {
      return AssetLifeAPI.get('load/aggregated_meter', {
        params: {
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          start_time: startDate,
          end_time: endDate,
        },
      }).then(res => res.data);
    }
);

export const resetScenarioRunInfoAction = createAction(
  'network-loading/RESET_SCENARIO_RUN_INFO',
  (): Pick<NetworkLoading.Root, 'scenarioRunInfo'> => ({ scenarioRunInfo: null })
);

export const fetchScenarioRunInfoAction = createAction(
  'network-loading/FETCH_SCENARIO_RUN_INFO',
  async ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'scenarioRunInfo'>> => {
      const state = getState();
      const scenarioRunInfo = scenarioRunInfoSelector(state);
      if (scenarioRunInfo) dispatch(resetScenarioRunInfoAction());
      return AssetLifeAPI.get('scenarios/scenario_run_info', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then((res: any) => ({
        scenarioRunInfo: {
          customer_data_timestamp:
            res.data?.customer_data_timestamp && moment.utc(res.data.customer_data_timestamp).toISOString(),
          data_updated_at: res.data?.data_updated_at && moment.utc(res.data.data_updated_at).toISOString(),
          simulation_end: res.data?.simulation_end && moment.utc(res.data.simulation_end).toISOString(),
        },
      }));
    }
);

interface Params {
  portfolioId: Layouts.Root['portfolioId'];
  scenarioId: Layouts.ScenarioId;
  simulationId: Layouts.Root['simulationId'];
  versionId: number;
  year: Layouts.Filters['year'];
  voltage: Layouts.Filters['voltage'];
  voltage_side: Layouts.Filters['voltageSide'];
  percentile: Layouts.Filters['percentile'];
  flex: Layouts.Filters['flex'];
  showOverloadedAssets: Layouts.Filters['showOverloadedAssets'];
}

export const getTransformersLoadingChartData = createAction(
  'network-loading/GET_TRANSFORMERS_LOADING_CHART_DATA',
  ({
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    voltage,
    year,
    percentile,
    voltage_side,
    flex,
    showOverloadedAssets,
  }: Params) =>
    (): Promise<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'> | null> => {
      return AssetLifeAPI.get('load/load_transformers_hist', {
        params: {
          voltage,
          year,
          percentile,
          voltage_side,
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          version_id: versionId,
          flex,
          show_overloaded_assets: showOverloadedAssets,
        },
      }).then(res => res.data);
    }
);

export const getTransformersVoltageChartData = createAction(
  'network-loading/GET_TRANSFORMERS_VOLTAGE_CHART_DATA',
  ({
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    voltage,
    year,
    percentile,
    voltage_side,
    flex,
  }: Omit<Params, 'showOverloadedAssets'>) =>
    (): Promise<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x'> | null> => {
      return AssetLifeAPI.get('load/voltage_transformers_hist_plot', {
        params: {
          voltage,
          year,
          percentile,
          voltage_side,
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          version_id: versionId,
          flex,
        },
      }).then(res => res.data);
    }
);

export const getTotalLoadingProfileChartData = createAction(
  'network-loading/GET_TOTAL_LOADING_PROFILE_CHART_DATA',
  ({ portfolioId, scenarioId }: { portfolioId: Layouts.Root['portfolioId']; scenarioId: Layouts.ScenarioId }) =>
    (): Promise<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'> | null> => {
      return AssetLifeAPI.get('load/load_meter_profile_plot', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId },
      }).then(res => res.data);
    }
);

export const setFutureChartData = createAction('network-loading/SET_FUTURE_CHART_DATA');

export const fetchFutureChartData = createAction(
  'network-loading/FETCH_FUTURE_CHART_DATA',
  ({
    voltage,
    asset_class,
    portfolioId,
    scenarioId,
    simulationId,
    versionId,
    storeKey,
    skipStoreUpdate = false,
  }: {
    asset_class: 'Transformer' | 'Cable';
    voltage: Layouts.Filters['voltage'];
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    simulationId: Layouts.Root['simulationId'];
    versionId: number;
    storeKey: string;
    skipStoreUpdate?: boolean;
  }) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<NetworkLoading.Root, 'futureChartDataHash'>> => {
      const state = getState();
      const portfolioOptions = portfolioOptionsSelector(state);
      const portfolioOptionLabel = portfolioOptions!.find(option => option.value === portfolioId)?.label;
      const scenarioOptionsHash = scenarioOptionsHashSelector(state);
      const scenarioOptionLabel = scenarioOptionsHash?.[portfolioId!]?.find(
        option => option.value === scenarioId
      )?.label;
      const simulationOptionsHash = simulationOptionsHashSelector(state);
      const simulationOptionLabel = simulationOptionsHash?.[`${portfolioId}_${scenarioId}`]!.find(
        option => option.value === simulationId && option.versionId === versionId
      )?.label;
      return AssetLifeAPI.get('load/summary_results', {
        params: {
          voltage,
          asset_class,
          portfolio_id: portfolioId,
          scenario_id: scenarioId,
          simulation_id: simulationId,
          version_id: versionId,
        },
      }).then((res: { data: Pick<Shared.ChartAPIResponse, 'data' | 'yaxis_title'>[] | null }) => {
        const futureChartDataHash = {
          ...(skipStoreUpdate ? {} : futureChartDataHashSelector(getState())),
          [storeKey]: res.data?.length
            ? res.data.map(chart => ({
                series: [
                  {
                    name: `<div>${portfolioOptionLabel} - ${scenarioOptionLabel}</div>${simulationOptionLabel ? `<small>${simulationOptionLabel}</small>` : ''}`,
                    type: 'line' as any,
                    data: chart.data,
                  },
                ],
                title: '',
                xAxisTitle: '',
                yAxisTitle: chart.yaxis_title,
              }))
            : [{ series: [] }, { series: [] }],
        };
        return { futureChartDataHash, skipStoreUpdate };
      });
    }
);

export const getReliabilityChartData = createAction(
  'network-loading/GET_RELIABILITY_CHART_DATA',
  ({
    portfolioId,
    versionId,
    cnaim_id,
    uuid,
  }: {
    portfolioId: number;
    versionId: number;
    cnaim_id: number;
    uuid: string;
  }) =>
    (): Promise<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x' | 'categories'> | null> => {
      return AssetLifeAPI.get('/load/probability_of_failure_plot', {
        params: { portfolio_id: portfolioId, version_id: versionId, cnaim_id, id: uuid },
      }).then(res => res.data);
    }
);

export const getEvolutionChartData = createAction(
  'network-loading/GET_EVOLUTION_CHART_DATA',
  ({
    portfolioId,
    scenarioId,
    uuid,
    flex,
  }: {
    portfolioId: Layouts.Root['portfolioId'];
    scenarioId: Layouts.ScenarioId;
    uuid: string;
    flex: Layouts.Filters['flex'];
  }) =>
    (): Promise<Record<Type.SelectEvolutionTypes, Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x'>> | null> => {
      return AssetLifeAPI.get('load/asset_evolution', {
        params: { portfolio_id: portfolioId, scenario_id: scenarioId, id: uuid, flex },
      }).then(res => {
        return Object.keys(res.data).reduce((acc: any, key: string) => {
          const item = res.data[key];
          acc[key] = {
            title: item.title || '',
            xAxisTitle: item.xaxis_title || '',
            yAxisTitle: item.yaxis_title || '',
            series: item.series.map((i: any) => ({ name: i.group || '', data: i.data })) || [],
            categories: item.categories!,
          };
          return acc;
        }, {});
      });
    }
);
