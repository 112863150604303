import React from 'react';
import { Switch, Route } from 'react-router';
import { PageAppExceptions } from 'pages';
import {
  ViewForecastedScenarios,
  ViewInvestment,
  ViewCO2e,
  ViewRawData,
  ViewCalculationSchedule,
} from 'components/Setup';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredPortfolioLoadflowEnabledCheck from './RequiredPortfolioLoadflowEnabledCheck';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageSetup: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isSetupEnabled}>
    <RequiredPortfolioLoadflowEnabledCheck>
      <Switch>
        <Route exact path={Routes.Setup} component={ViewForecastedScenarios} />
        <Route
          path={Routes.SetupInvestment}
          render={() => (
            <RequiredScenarioCheck>
              <ViewInvestment />
            </RequiredScenarioCheck>
          )}
        />
        <Route path={Routes.SetupCO2e} render={() => <ViewCO2e />} />
        <Route exact path={Routes.SetupRawData} render={() => <ViewRawData />} />
        <Route exact path={Routes.SetupCalculationSchedule} render={() => <ViewCalculationSchedule />} />
        <Route path="*" component={PageAppExceptions} />
      </Switch>
    </RequiredPortfolioLoadflowEnabledCheck>
  </PagePermissionsProvider>
);

export default PageSetup;
