import axios from 'axios';
import { createAction } from 'redux-actions';
import { _keyBy } from '@utiligize/shared/utils';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchUsersAction: any = createAction('users/FETCH', async () => {
  return (dispatch: Shared.CustomDispatch): Promise<Record<string, Users.User>> => {
    return axios
      .get('api/admin/v1/secure/users', {
        params: { extendedInformation: true },
      })
      .then((res: any) => _keyBy(res.data, (item: Users.User) => `_${item.id}_`));
  };
});
