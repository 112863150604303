import { createSelector } from 'reselect';
import { getLocation } from 'connected-react-router';
import {
  portfolioOptionSelector,
  scenarioSelectedOptionSelector,
  simulationOptionSelector,
} from 'modules/options/selectors';
import { appRootSelector } from './app/selectors';
import { getStorageItem } from 'utils';
import { Routes, StorageKeys } from 'constants/index';

const RoutesGroup = {
  General: [Routes.Map, Routes.CO2e],
  'Forecast & Investment': [
    Routes.NetworkLoading,
    Routes.N1,
    Routes.Customers,
    Routes.Investment,
    Routes.DataQuality,
    Routes.Setup,
    Routes.NewLoad,
    Routes.AnalysisTool,
  ],
  'Maintenance & Planning': [
    Routes.Tasks,
    Routes.Meters,
    Routes.Assets,
    Routes.Maintenance,
    Routes.AssetModels,
    Routes.Forms,
    Routes.History,
    Routes.DataManuals,
  ],
  Reinvestment: [Routes.Reliability, Routes.MonetizedRisk],
  Admin: [Routes.Admin],
};

const getUrlGroup = (path: string) =>
  Object.keys(RoutesGroup).find(key =>
    RoutesGroup[key as keyof typeof RoutesGroup].some(route => path.includes(route))
  );

export const mixpanelPageViewEventPayloadSelector = createSelector(
  [
    appRootSelector,
    portfolioOptionSelector,
    scenarioSelectedOptionSelector,
    simulationOptionSelector,
    getLocation as any,
  ],
  (app, portfolioOption, scenarioSelectedOption, simulationOption, location: Location) => {
    const claims: Users.Claims | null = getStorageItem(StorageKeys.USER_CLAIMS);
    const lastRoute = getStorageItem(StorageKeys.LAST_ROUTE);

    const skipTracking =
      !process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN ||
      !portfolioOption?.label ||
      !scenarioSelectedOption?.label ||
      !app.appCurrentUserFetched ||
      !app.selectedTimezone ||
      claims?.email.includes('@this.email.is.invalid');

    if (skipTracking) return null;
    const currentRoute = `${location.pathname}${location.hash}`;

    return {
      distinct_id: claims?.email,
      url_previous: lastRoute === currentRoute ? undefined : 'lastRoute',
      url_current: currentRoute,
      url_group: getUrlGroup(location.pathname),
      is_admin: app.isAdmin,
      tenant: getStorageItem(StorageKeys.SELECTED_TENANT),
      portfolio: portfolioOption?.label,
      scenario: scenarioSelectedOption?.label,
      simulation: simulationOption?.label,
      user: app.fullName,
      app_version: process.env.REACT_APP_VERSION,
      app_language: app.lang,
      app_timezone: app.selectedTimezone,
    };
  }
);
