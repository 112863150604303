import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useRecalculateButton } from 'hooks';
import { isLiveDataConnectionSelector } from 'modules/app/selectors';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { setPaginationAction } from 'modules/layouts';
import { recalculateMaintenancePlanAction } from 'modules/maintenance';
import { MaintenanceDetailedPlanTable } from 'components/_tables';
import { MaintenanceDetailedPlanModal } from 'components/_modals';
import { DataTableTemplate, SelectYear, SelectAssetCategories, RecalculationSpinner } from 'components/_common';
import { PaginationType } from 'constants/index';

const DetailedPlan: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { loading, ButtonRecalculate } = useRecalculateButton(recalculateMaintenancePlanAction);
  const { filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(PaginationType.MAINTENANCE_PLAN));
  const isLiveDataConnection = useSelector(isLiveDataConnectionSelector);

  const handleChangeYearSelect = useCallback(
    (value: Type.SelectOption) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.MAINTENANCE_PLAN,
          modifier: { filters: { ...filters, year: value?.value || null }, offset: 0 },
        })
      );
    },
    [dispatch, filters]
  );

  const handleChangeAssetCategoriesSelect = useCallback(
    (value: Type.SelectOption) => {
      dispatch(
        setPaginationAction({
          type: PaginationType.MAINTENANCE_PLAN,
          modifier: { filters: { ...filters, assetcategoryCode: value.value }, offset: 0 },
        })
      );
    },
    [dispatch, filters]
  );

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectYear
            isSearchable={false}
            value={filters?.year!}
            onChange={handleChangeYearSelect}
            labelKey=""
            variant="small"
            mutedTextLabelKey="Year"
          />
        </Col>
        <Col xs={3}>
          <SelectAssetCategories
            labelKey=""
            value={filters?.assetcategoryCode || ''}
            onChange={handleChangeAssetCategoriesSelect as any}
            excludeSyntheticOptions
            isSearchable={false}
            variant="small"
            mutedTextLabelKey="Asset categories"
          />
        </Col>
        {isLiveDataConnection && (
          <Col xs="auto">
            <MaintenanceDetailedPlanModal />
          </Col>
        )}
        {isLiveDataConnection && <Col className="text-right">{ButtonRecalculate}</Col>}
      </Row>
      {loading ? <RecalculationSpinner /> : <MaintenanceDetailedPlanTable />}
    </DataTableTemplate>
  );
};

export default DetailedPlan;
