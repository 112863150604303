import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { fetchTaskAssetsByCodeAction } from 'modules/assets';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { isNKTenant } from 'utils';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  autoFocus?: boolean;
  values: string[] | Tasks.CreateTaskActionProps['assets'];
}

const SelectAssetCodes: React.FC<Props> = ({ labelKey = 'Asset codes', values, isMulti, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<Type.SelectOption<string>[] | null>(null);

  const selectValues = useMemo(
    () =>
      values.map(value =>
        typeof value === 'string'
          ? { label: value, value }
          : { value: value.code, label: value.code, categoryCode: value.categoryCode }
      ),
    [values]
  );

  const debounced = useDebouncedCallback((query: string) => {
    setIsLoading(true);
    dispatch(fetchTaskAssetsByCodeAction(query))
      .then((action: Shared.ReduxAction<Asset.TaskAssetCodeItem[]>) => {
        setOptions(
          action.payload.map(({ assetCode, categoryCode }) => ({ value: assetCode, label: assetCode, categoryCode }))
        );
      })
      .finally(() => setIsLoading(false));
  }, 1000);

  useEffect(() => debounced.cancel, [debounced.cancel]);

  const handleInputChange = useCallback(
    (newValue: string) => {
      setInputValue(newValue);
      // 'GL-' check must be clarified
      if (newValue.length < (isNKTenant ? 3 : 2) || newValue === 'GL-') {
        setOptions(null);
        setIsLoading(false);
        debounced.cancel();
      } else {
        debounced(newValue);
      }
    },
    [debounced]
  );

  return (
    <FormReactSelect
      {...props}
      value={selectValues}
      options={options || []}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      isLoading={isLoading}
      placeholderKey="Type to search"
      menuIsOpen={Boolean(options)}
      labelKey={labelKey}
      isMulti={isMulti}
      isClearable
      variant="small"
    />
  );
};

export default SelectAssetCodes;
