import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { materialsCountSelector, materialsSelector } from 'modules/tasks/selectors';
import { fetchTaskMaterialsAction, deleteTaskMaterialAction } from 'modules/tasks';
import { setModalConfirmationMetaAction, setLayoutAction } from 'modules/layouts';
import { DataTable, Button } from 'components/_common';
import { PaginationType } from 'constants/index';
import { IconEdit, IconDelete } from '@utiligize/shared/resources';

interface Props {
  maxTableHeight?: string;
}

const TableMaterials: React.FC<Props> = ({ maxTableHeight }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();

  const count = useSelector(materialsCountSelector);
  const items = useSelector(materialsSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchTaskMaterialsAction(params)).then(
        (action: Shared.ReduxAction<{ materialsHash: Record<string, Tasks.Material> }>) =>
          Object.values(action.payload?.materialsHash || {})
      ),
    [dispatch]
  );

  const handleEditBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: string | null = event.currentTarget.getAttribute('data-id');
      if (id) dispatch(setLayoutAction({ tasksMaterialsModalId: Number(id) }));
    },
    [dispatch]
  );

  const handleDeleteBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const id: number = Number(event.currentTarget.getAttribute('data-id'));
      if (!id) return;
      dispatch(setModalConfirmationMetaAction({ onConfirm: () => dispatch(deleteTaskMaterialAction(id)) }));
    },
    [dispatch]
  );

  return (
    <DataTable
      paginationType={PaginationType.TASKS_MATERIALS}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      maxHeight={maxTableHeight}
    >
      {items?.map((item: Tasks.Material) => (
        <tr key={item.id}>
          <td>{item.name}</td>
          <td>{item.code}</td>
          <td>{item.unit}</td>
          <td>{item.group}</td>
          <td className="text-right">
            <Button tooltipKey="Edit" icon={<IconEdit />} data-id={item.id} onClick={handleEditBtnClick} size="small" />
            <Button
              marginLeft
              tooltipKey="Delete"
              icon={<IconDelete />}
              data-id={item.id}
              onClick={handleDeleteBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableMaterials;
