import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { externalTimeSystemSelector } from 'modules/app/selectors';
import { ViewTaskDetails, ViewRepeatedTasks, ViewTasks, ViewEmployees, ViewTimeOverview } from 'components/Tasks';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageTasks: React.FC = () => {
  const externalTimeSystem = useSelector(externalTimeSystemSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isTasksEnabled}>
      <RequiredScenarioCheck>
        <RequiredSimulationCheck>
          <Switch>
            <Route path={Routes.TasksEmployees} component={ViewEmployees} />
            <Route path={Routes.TasksTimeOverview} component={ViewTimeOverview} />
            {externalTimeSystem && <Route path={Routes.TasksRepeatedTasks} component={ViewRepeatedTasks} />}
            {/* Routes.Meters route must be specified last  */}
            <Route
              exact
              path={Routes.Tasks}
              render={
                ((props: RouteComponentProps<{ id: string }>) => {
                  return props.match.params.id ? <ViewTaskDetails {...props} /> : <ViewTasks />;
                }) as any
              }
            />
          </Switch>
        </RequiredSimulationCheck>
      </RequiredScenarioCheck>
    </PagePermissionsProvider>
  );
};

export default PageTasks;
