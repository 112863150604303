import React from 'react';
import { useSelector } from 'react-redux';
import { scenarioIdSelector } from 'modules/layouts/selectors';
import { PageAppExceptions } from 'pages';
import { AppErrorsTypes } from 'constants/index';

const RequiredScenarioCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const scenarioId = useSelector(scenarioIdSelector);

  if (!scenarioId) {
    return <PageAppExceptions appError={AppErrorsTypes.NO_SCENARIOS} />;
  }

  return <>{children}</>;
};

export default RequiredScenarioCheck;
