import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { getCustomersConnectedEvolutionChartData } from 'modules/dataQuality';
import { Colors } from 'components/_charts/Chart';
import { Tabs, Tab, ContentContainer, ContentCard } from 'components/_common';
import TableCustomersConnected from './TableCustomersConnected';
import ChartCustomersConnectedEvolution, { ChartDataProps } from './ChartCustomersConnectedEvolution';
import { Routes } from 'constants/index';

const ViewCustomersConnected: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const { getIntl } = useLocale();
  const [chartData, setChartData] = useState<ChartDataProps[] | null>(null);

  useEffect(() => {
    setChartData(null);
    dispatch(getCustomersConnectedEvolutionChartData())
      .then(
        (action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'data' | 'datetime_x' | 'categories'>[] | null>) => {
          setChartData(
            action.payload?.map(chart => ({
              series: chart.series.map((i, index: number) => ({
                name: i.group || '',
                type: 'line' as any,
                data: i.data.map(([, value]: any) => value),
                color: Colors[index],
              })),
              categories: chart.series[0].data.map(([date]: any) => date),
              title: chart.title,
              xAxisTitle: chart.xaxis_title,
              yAxisTitle: chart.yaxis_title,
            })) || []
          );
        }
      )
      .catch(() => setChartData([{ series: [] }, { series: [] }]));
  }, [dispatch]);

  return (
    <Tabs mountOnEnter unmountOnExit>
      <Tab id="tab__customers_connected_table" eventKey={Routes.DataQualityCustomersConnected} title={getIntl('Table')}>
        <TableCustomersConnected />
      </Tab>
      <Tab
        id="tab__customers_connected_evolution"
        eventKey={Routes.DataQualityCustomersConnectedEvolution}
        title={getIntl('Evolution')}
      >
        <ContentContainer>
          <ContentCard marginBottom>
            <ChartCustomersConnectedEvolution {...(chartData?.[0] || {})} />
          </ContentCard>
          <ContentCard>
            <ChartCustomersConnectedEvolution {...(chartData?.[1] || {})} />
          </ContentCard>
        </ContentContainer>
      </Tab>
    </Tabs>
  );
};

export default ViewCustomersConnected;
