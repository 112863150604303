import axios from 'axios';
import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';

// ------------------------------------
// Actions
// ------------------------------------

export const fetchServiceTimeAction: any = createAction(
  'tasks/FETCH_SERVICE_TIME',
  (year: number) => (): Promise<Record<string, Tasks.ServiceTime>> =>
    axios.get(`api/admin/v1/secure/scheduledTime/${year}`).then(res => res.data)
);

export const updateServiceTimeAction: any = createAction(
  'tasks/UPDATE_SERVICE_TIME',
  async (serviceTimes: Tasks.ServiceTime[], { skipToast }: { skipToast: boolean } = { skipToast: false }) =>
    (dispatch: Shared.CustomDispatch): Promise<void> =>
      axios.put(`api/admin/v1/secure/scheduledTime`, serviceTimes).then(() => {
        if (!skipToast) dispatch(setSuccessToastAction('Service time has been saved'));
      })
);

export const copyFromPreviousYearAction: any = createAction(
  'tasks/COPY_FROM_PREVIOUS_YEAR',
  (year: number) =>
    async (dispatch: Shared.CustomDispatch): Promise<Tasks.ServiceTime[]> => {
      return axios.put(`api/admin/v1/secure/scheduledTime/copy?originYear=${year - 1}&targetYear=${year}`).then(() => {
        return dispatch(fetchServiceTimeAction(year)).then((action: Shared.ReduxAction<Tasks.ServiceTime[]>) => {
          dispatch(setSuccessToastAction('Service time has been copied'));
          return action.payload;
        });
      });
    }
);

export const fetchTaskExpectedTimeAction: any = createAction(
  'tasks/FETCH_TASK_SERVICE_TIME',
  (assetCategoryCode: Type.AssetCategories, appAction: Type.TaskAppActions) => (): Promise<number> =>
    axios
      .get(`api/admin/v1/secure/scheduledTime/assetCategories/${assetCategoryCode}/appActions/${appAction}`)
      .then(res => res.data)
);
