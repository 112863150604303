import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from 'hooks';
import { getAgeDistributionChartData } from 'modules/dataQuality';
import { portfolioIdSelector } from 'modules/layouts/selectors';
import { simulationVersionIdSelector } from 'modules/options/selectors';
import Chart, { Colors, getBaseOptions } from 'components/_charts/Chart';

interface Props {
  cnaim_id: Layouts.Filters['cnaim_id'];
}

interface ChartDataProps {
  series: Shared.SeriesOptionsType[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartAgeDistribution: React.FC<Props> = ({ cnaim_id }) => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const portfolioId = useSelector(portfolioIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);

  useEffect(() => {
    setChartData(null);
    if (!portfolioId || !versionId || !cnaim_id) return;
    dispatch(getAgeDistributionChartData({ portfolioId, versionId, cnaim_id }))
      .then(
        (action: Shared.ReduxAction<Omit<Shared.ChartAPIResponse, 'series' | 'datetime_x' | 'categories'> | null>) => {
          setChartData({
            series: [
              {
                name: action.payload?.title || '',
                type: 'column' as any,
                data: action.payload?.data,
                color: Colors[0],
              },
            ],
            title: action.payload?.title || '',
            xAxisTitle: action.payload?.xaxis_title || '',
            yAxisTitle: action.payload?.yaxis_title || '',
          });
        }
      )
      .catch(console.error);
  }, [dispatch, portfolioId, versionId, cnaim_id]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);
    return {
      ...baseOptions,
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `<b>${getIntl('Count')}:</b> ${this.point.y}<br><b>${getIntl('Age')}:</b> ${this.point.x}`;
        },
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
      legend: {
        enabled: false,
      },
    };
  }, [chartData, getIntl]) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_data_quality_age_distribution" />;
};

export default ChartAgeDistribution;
