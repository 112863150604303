import { maintenanceTimeByActionTypeAssetCategoriesSelector } from 'modules/layouts/selectors';
import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.maintenance;

export const maintenancePlansFetchedSelector = createSelector(
  rootSelector,
  maintenance => maintenance.maintenancePlansFetched
);

export const maintenancePlansCountSelector = createSelector(
  rootSelector,
  maintenance => maintenance.maintenancePlansCount
);

const maintenancePlansHashSelector = createSelector(rootSelector, maintenance => maintenance.maintenancePlansHash);

export const maintenancePlansSelector = createSelector(maintenancePlansHashSelector, maintenancePlansHash =>
  Object.values(maintenancePlansHash)
);

export const maintenancePlanSelectorFactory = (id: number | null) =>
  createSelector(maintenancePlansHashSelector, maintenancePlansHash => maintenancePlansHash[`_${id}_`]);

export const maintenanceSummaryCountSelector = createSelector(
  rootSelector,
  maintenance => maintenance.maintenanceSummaryCount
);

const maintenanceSummaryHashSelector = createSelector(rootSelector, maintenance => maintenance.maintenanceSummaryHash);

export const maintenanceSummarySelector = createSelector(maintenanceSummaryHashSelector, maintenanceSummaryHash =>
  Object.values(maintenanceSummaryHash)
);

export const maintenanceDetailedPlanCountSelector = createSelector(
  rootSelector,
  maintenance => maintenance.maintenanceDetailedPlanCount
);

const maintenanceDetailedPlanHashSelector = createSelector(
  rootSelector,
  maintenance => maintenance.maintenanceDetailedPlanHash
);

export const maintenanceDetailedPlanSelector = createSelector(
  maintenanceDetailedPlanHashSelector,
  maintenanceDetailedPlanHash => Object.values(maintenanceDetailedPlanHash)
);

export const maintenancePlanCNAIMCountSelector = createSelector(
  rootSelector,
  maintenance => maintenance.maintenancePlanCNAIMCount
);

const maintenancePlanCNAIMHashSelector = createSelector(
  rootSelector,
  maintenance => maintenance.maintenancePlanCNAIMHash
);

export const maintenancePlanCNAIMSelector = createSelector(maintenancePlanCNAIMHashSelector, maintenancePlanCNAIMHash =>
  Object.values(maintenancePlanCNAIMHash)
);

export const timeByActionTypeChartDataSelector = createSelector(
  rootSelector,
  maintenance => maintenance.timeByActionTypeChartData
);

export const timeByActionTypeChartFilteredDataSelector = createSelector(
  [timeByActionTypeChartDataSelector, maintenanceTimeByActionTypeAssetCategoriesSelector],
  (chartData, assetCategories) => {
    if (!assetCategories?.length || !chartData) return chartData;
    const indexes: number[] = [];
    const categories = chartData.categories.filter((item, index) => {
      if (!assetCategories.includes(item)) return false;
      indexes.push(index);
      return true;
    });
    const series = chartData.series.map((s: any) => ({
      ...s,
      data: s.data.filter((item: any, index: number) => indexes.includes(index)),
    }));
    return { ...chartData, categories, series };
  }
);

export const conditionAssessmentChartDataSelector = createSelector(
  rootSelector,
  maintenance => maintenance.conditionAssessmentChartData
);
