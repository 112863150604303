import React, { useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { featureTogglesSelector } from 'modules/layouts/selectors';
import { setFeatureToggleAction } from 'modules/layouts';
import { ContentContainer, ContentCard, Checkbox } from 'components/_common';
import { isVekselTenant, isNordEnergiTenant } from 'utils';
import { StorageKeys } from 'constants/index';

const ViewFeatureToggles: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const featureToggles = useSelector(featureTogglesSelector);

  const handleCheckboxClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, checked } = event.currentTarget;
      dispatch(setFeatureToggleAction({ [name]: checked }));
    },
    [dispatch]
  );

  return (
    <ContentContainer className="border-top-0">
      <ContentCard>
        <Row>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Network loading - Voltage drop"
              name={StorageKeys.FEATURE_TOGGLE_VOLTAGE_DROP as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_TOGGLE_VOLTAGE_DROP]}
              onChange={handleCheckboxClick}
            />
          </Col>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Network loading - Short circuit"
              name={StorageKeys.FEATURE_SHORT_CIRCUIT as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_SHORT_CIRCUIT]}
              onChange={handleCheckboxClick}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Map - Feeders animation"
              name={StorageKeys.FEATURE_TOGGLE_FEEDERS_ANIMATION as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_TOGGLE_FEEDERS_ANIMATION]}
              onChange={handleCheckboxClick}
            />
          </Col>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Map - High voltage cables animation"
              name={StorageKeys.FEATURE_HIGH_VOLTAGE_CABLES_ANIMATION as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_HIGH_VOLTAGE_CABLES_ANIMATION]}
              onChange={handleCheckboxClick}
            />
          </Col>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Map - Extend grid"
              name={StorageKeys.FEATURE_TOGGLE_DRAW_ASSETS as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_TOGGLE_DRAW_ASSETS]}
              onChange={handleCheckboxClick}
            />
          </Col>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Map - Connections"
              name={StorageKeys.FEATURE_MAP_CONNECTIONS as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_MAP_CONNECTIONS]}
              onChange={handleCheckboxClick}
            />
          </Col>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Single-line diagram - Show chart type control"
              name={StorageKeys.FEATURE_TOGGLE_SINGLE_LINE_CONTROLS as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_TOGGLE_SINGLE_LINE_CONTROLS]}
              onChange={handleCheckboxClick}
            />
          </Col>
          {isVekselTenant && (
            <Col as={Col} xs={6}>
              <Checkbox
                labelKey="Map - GIS layers"
                name={StorageKeys.FEATURE_GIS_LAYERS as string}
                className="icheck-primary"
                checked={featureToggles[StorageKeys.FEATURE_GIS_LAYERS]}
                onChange={handleCheckboxClick}
              />
            </Col>
          )}
          {isNordEnergiTenant && (
            <Col as={Col} xs={6}>
              <Checkbox
                labelKey="Map - DER -> AI themes"
                name={StorageKeys.FEATURE_MAP_DER_AI_THEMES as string}
                className="icheck-primary"
                checked={featureToggles[StorageKeys.FEATURE_MAP_DER_AI_THEMES]}
                onChange={handleCheckboxClick}
              />
            </Col>
          )}
        </Row>
        <hr />
        <Row>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Setup - Investment - Work prices"
              name={StorageKeys.FEATURE_TOGGLE_WORK_PRICES as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_TOGGLE_WORK_PRICES]}
              onChange={handleCheckboxClick}
            />
          </Col>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Maintenance - Plan (CNAIM)"
              name={StorageKeys.FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_TOGGLE_MAINTENANCE_PLAN_CNAIM]}
              onChange={handleCheckboxClick}
            />
          </Col>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Customers - Detection type filter"
              name={StorageKeys.FEATURE_CUSTOMERS_DETECTION_TYPE as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_CUSTOMERS_DETECTION_TYPE]}
              onChange={handleCheckboxClick}
            />
          </Col>
          <Col as={Col} xs={6}>
            <Checkbox
              labelKey="Show multiple currencies"
              name={StorageKeys.FEATURE_CURRENCIES as string}
              className="icheck-primary"
              checked={featureToggles[StorageKeys.FEATURE_CURRENCIES]}
              onChange={handleCheckboxClick}
            />
          </Col>
        </Row>
      </ContentCard>
    </ContentContainer>
  );
};

export default ViewFeatureToggles;
