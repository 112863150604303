import axios from 'axios';
import { createAction } from 'redux-actions';
import { setSuccessToastAction } from 'modules/layouts';
import { paginationSelectorFactory } from 'modules/layouts/selectors';
import { manualsItemByIdSelectorFactory } from 'modules/manuals/selectors';
import { appLangSelector } from 'modules/app/selectors';
import { _keyBy } from '@utiligize/shared/utils';
import { PaginationType } from 'constants/index';
import { mapPanelSelectedAssetUUIDSelector, assetDetailsSelectedAssetCodeSelector } from 'modules/router/selectors';

const baseUrl = '/api/admin/v1/secure/datamanuals';

// ------------------------------------
// Actions
// ------------------------------------
export const fetchManualsAction: any = createAction(
  'manuals/FETCH_MANUALS',
  async ({ uuid, assetCode }: { uuid?: string; assetCode?: string } = {}) =>
    (
      dispatch: Shared.CustomDispatch,
      getState: () => State.Root
    ): Promise<Pick<Manuals.Root, 'count' | 'itemsHash'>> => {
      const state: State.Root = getState();
      const { limit, offset, sort, column, query, filters } = paginationSelectorFactory(PaginationType.MANUALS)(state);
      return axios
        .get(`/api/admin/v2/secure/datamanuals`, {
          params: {
            limit,
            offset,
            sort,
            column,
            query,
            lang: appLangSelector(state).toLowerCase(),
            filters: (uuid && { uuid }) || (assetCode && { assetCode }) || filters,
          },
        })
        .then((res: any) => ({
          count: res.data.count,
          itemsHash: _keyBy(res.data.rows, (item: Manuals.Item) => `_${item.id}_`),
        }));
    }
);

export const fetchManualAction: any = createAction(
  'manuals/FETCH_MANUAL',
  async (id: number) =>
    (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<Record<string, Manuals.Item>> => {
      return axios.get(`${baseUrl}/${id}`).then(response => {
        const item: Manuals.Item = manualsItemByIdSelectorFactory(id)(getState()) || ({} as Manuals.Item);
        return { [`_${id}_`]: { ...item, link: response.data.link } };
      });
    }
);

export const deleteManualByIdAction: any = createAction('manuals/DELETE_MANUAL_BY_ID', async (id: string) => {
  return (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> =>
    axios.delete(`${baseUrl}/${id}`).then(async () => {
      // update manuals for AssetDetails or UnifiedAssetPanel
      const state = getState();
      const uuid = mapPanelSelectedAssetUUIDSelector(state);
      const assetCode = assetDetailsSelectedAssetCodeSelector(state);
      await dispatch(fetchManualsAction({ assetCode, uuid }));
      dispatch(setSuccessToastAction('Data manual has been deleted'));
    });
});

export const createManualAction: any = createAction(
  'manuals/CREATE_MANUAL',
  async ({ formData, categoryId, modelIds, assetCode }: Type.CreateManualActionProps) =>
    (dispatch: Shared.CustomDispatch): Promise<void> => {
      // FixMe. POST manual API must be fully reworked
      return axios.post(baseUrl, formData).then(async res => {
        const { id } = res.data;
        const categoryPromise = categoryId ? axios.post(`${baseUrl}/${id}/links/category`, { categoryId }) : null;
        const modelPromises = modelIds.length
          ? modelIds.map((modelId: number) => axios.post(`${baseUrl}/${id}/links/model`, { modelId }))
          : [];
        const assetPromise = assetCode ? axios.post(`${baseUrl}/${id}/links/asset`, { assetCode }) : null;
        await Promise.all([categoryPromise, assetPromise].concat(modelPromises).filter(Boolean));
        await dispatch(fetchManualsAction());
      });
    }
);

export const updateManualAction: any = createAction(
  'manuals/UPDATE_MANUAL',
  async ({ manualId, nextName, categoryId, modelIds, assetCode }: Type.UpdateManualActionProps) =>
    async (dispatch: Shared.CustomDispatch, getState: () => State.Root): Promise<void> => {
      // FixMe. PUT manual API must be fully reworked
      const {
        name,
        assignedModels,
        assetCode: prevAssetCode,
        assetCodeLinkId,
        categoryId: prevCategoryId,
        categoryLinkId,
      } = manualsItemByIdSelectorFactory(manualId)(getState()) || ({} as Manuals.Item);

      // deletedLinksIds - all models links which will be deleted
      // existingModelIds - rest of items
      const { deletedLinksIds, existingModelIds } = (assignedModels as AssetModels.Item[]).reduce(
        (acc: { deletedLinksIds: number[]; existingModelIds: number[] }, assignedModel) => {
          if (!modelIds.includes(assignedModel.modelId)) {
            acc.deletedLinksIds.push(assignedModel.linkId as number);
          } else {
            acc.existingModelIds.push(assignedModel.modelId);
          }
          return acc;
        },
        { deletedLinksIds: [], existingModelIds: [] }
      );

      const dataManualPromise = nextName !== name ? axios.put(`${baseUrl}/${manualId}`, { name: nextName }) : null;

      const deletedLinksIdsPromises = deletedLinksIds.map((linkId: number) => {
        return axios.delete(`${baseUrl}/${manualId}/links/${linkId}`);
      });

      const modelPromises = modelIds
        .filter((id: number) => !existingModelIds.includes(id))
        .map((modelId: number) => axios.post(`${baseUrl}/${manualId}/links/model`, { modelId }));

      const categoryPromise = (() => {
        if (categoryLinkId && !categoryId) return axios.delete(`${baseUrl}/${manualId}/links/${categoryId}`);
        if (prevCategoryId === categoryId) return null;
        return axios.post(`${baseUrl}/${manualId}/links/category`, { categoryId, manualLinkId: categoryLinkId });
      })();

      const assetPromise = (() => {
        if (assetCodeLinkId && !assetCode) return axios.delete(`${baseUrl}/${manualId}/links/${assetCodeLinkId}`);
        if (prevAssetCode === assetCode) return null;
        return axios.post(`${baseUrl}/${manualId}/links/asset`, { assetCode, manualLinkId: assetCodeLinkId });
      })();

      await Promise.all(
        [dataManualPromise, ...deletedLinksIdsPromises, ...modelPromises, categoryPromise, assetPromise].filter(Boolean)
      );

      // update manuals for AssetDetails or UnifiedAssetPanel
      const state = getState();
      const uuid = mapPanelSelectedAssetUUIDSelector(state);
      const assetDetailsSelectedAssetCode = assetDetailsSelectedAssetCodeSelector(state);
      await dispatch(fetchManualsAction({ uuid, assetCode: assetDetailsSelectedAssetCode }));
    }
);
