import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { useLocale } from 'hooks';
import {
  cablesSelector,
  cablesCountSelector,
  tableTransformersCablesWaitForDependenciesSelector,
} from 'modules/networkLoading/selectors';
import { fetchCablesAction } from 'modules/networkLoading';
import { setLayoutAction } from 'modules/layouts';
import {
  featureTogglesSelector,
  paginationSelectorFactory,
  portfolioIdSelector,
  scenarioIdSelector,
  simulationIdSelector,
} from 'modules/layouts/selectors';
import { DataTable, Button, SolvedOption } from 'components/_common';
import {
  PaginationType,
  Routes,
  ProfileModalTypes,
  TableHeaders,
  TableHeadersKeys,
  StorageKeys,
  SelectLoadingUnitValues,
} from 'constants/index';
import { IconDetails } from '@utiligize/shared/resources';

const TableCables: React.FC = () => {
  const { numberFormat, dateFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.CABLES;
  const isShortCircuitFeatureToggleEnabled = useSelector(featureTogglesSelector)?.[StorageKeys.FEATURE_SHORT_CIRCUIT];
  const { columns, filters }: Layouts.Pagination = useSelector(paginationSelectorFactory(type));
  const Keys = useMemo(
    () =>
      Object.keys(TableHeadersKeys[type]!).reduce((acc: any, key) => {
        const isAllowed =
          isShortCircuitFeatureToggleEnabled ||
          ![
            'ikss_ka_min',
            'ikss_ka_max',
            'skss_mva_min',
            'skss_mva_max',
            'sc_angle_from_degrees_min',
            'sc_angle_to_degrees_min',
            'sc_angle_from_degrees_max',
            'sc_angle_to_degrees_max',
            'skss_mva',
          ].includes(key);
        if (isAllowed) {
          acc[key] = (TableHeadersKeys[type] as any)![key];
        }
        return acc;
      }, {}),
    [type, isShortCircuitFeatureToggleEnabled]
  );
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);

  const items = useSelector(cablesSelector);
  const count = useSelector(cablesCountSelector);
  const waitForDependencies = useSelector(tableTransformersCablesWaitForDependenciesSelector);

  const sendRequest = useCallback(
    (params = { skipPagination: false, skipStoreUpdate: false }) =>
      dispatch(fetchCablesAction(params)).then((action: Shared.ReduxAction<Pick<NetworkLoading.Root, 'cablesHash'>>) =>
        Object.values(action.payload?.cablesHash || {})
      ),
    [dispatch]
  );

  const handleSeeProfileBtnClick = useCallback(
    (event: React.SyntheticEvent): void => {
      const assetName: string | null = event.currentTarget.getAttribute('data-id');
      if (!assetName) return;
      dispatch(
        setLayoutAction({
          profileModalMeta: { id: assetName, type: ProfileModalTypes.Cable },
          ...(filters?.year ? { selectedChartYear: filters?.year } : {}),
        })
      );
    },
    [dispatch, filters?.year]
  );

  const customHeaders = useMemo(
    () =>
      TableHeaders[type].reduce((acc: Type.DataTableHeader[], header) => {
        if (header.titleKey === 'Short circuit' && !isShortCircuitFeatureToggleEnabled) {
          return acc;
        }
        if (filters?.show_current_values === SelectLoadingUnitValues.True && header.titleKey === 'Max') {
          acc.push({
            ...header,
            subTitles: header.subTitles?.map(item => ({
              ...item,
              titleKey: item.titleKey === 'Loading (kW)' ? 'Loading (A)' : item.titleKey,
            })),
          });
          return acc;
        }
        if (filters?.show_current_values === SelectLoadingUnitValues.False && header.titleKey === 'Current (A)') {
          acc.push({ ...header, titleKey: 'Rating (kW)' });
          return acc;
        }
        if (filters?.show_current_values === SelectLoadingUnitValues.False && header.titleKey === 'Investment') {
          acc.push({
            ...header,
            subTitles: header.subTitles?.map(item => ({
              ...item,
              titleKey: item.titleKey === 'Future current (A)' ? 'Future rating (kW)' : item.titleKey,
            })),
          });
          return acc;
        }
        acc.push(header);
        return acc;
      }, []),
    [type, isShortCircuitFeatureToggleEnabled, filters?.show_current_values]
  );

  return (
    <DataTable
      customHeaders={customHeaders}
      paginationType={type}
      totalAmount={count}
      sendRequest={sendRequest}
      isDownloadCSVEnabled
      waitForDependencies={waitForDependencies}
      triggerTableUpdateDeps={[portfolioId, scenarioId, simulationId]}
      maxHeight="calc(100vh - 369px)"
      isSettingsButtonEnabled
    >
      {items?.map((item: NetworkLoading.Cable) => (
        <tr key={item.id}>
          {columns?.includes(Keys.customer_asset_name) && (
            <td>
              <Link to={generatePath(Routes.Map, { uuid: item.id })}>{item.customer_asset_name}</Link>
            </td>
          )}
          {columns?.includes(Keys.type) && <td>{item.type}</td>}
          {columns?.includes(Keys.distribution_feeder) && <td>{item.distribution_feeder ?? '-'}</td>}
          {columns?.includes(Keys.length) && <td>{numberFormat(Number(item.length))}</td>}
          {columns?.includes(Keys.date_commissioned) && (
            <td>{item.date_commissioned ? dateFormat(item.date_commissioned) : '-'}</td>
          )}
          {columns?.includes(Keys.max_capacity) && <td>{numberFormat(item.max_capacity)}</td>}
          {columns?.includes(Keys.ignore) && (
            <td className="text-center">
              <SolvedOption uuid={item.id} checked={item.ignore} paginationType={type} />
            </td>
          )}
          {columns?.includes(Keys.explanation) && <td>{item.explanation || '-'}</td>}
          {columns?.includes(Keys.max_loading) && <td>{numberFormat(item.max_loading)}</td>}
          {columns?.includes(Keys.max_reactive) && <td>{numberFormat(item.max_reactive)}</td>}
          {columns?.includes(Keys.grid_losses) && <td>{numberFormat(item.grid_losses)}</td>}
          {columns?.includes(Keys.max_loading_time) && <td>{dateFormat(item.max_loading_time, 'L LT')}</td>}
          {columns?.includes(Keys.utilization) && <td>{numberFormat(item.utilization)}</td>}
          {columns?.includes(Keys.annual_consumption) && (
            <td>{numberFormat(item.annual_consumption, { fallback: '-' })}</td>
          )}
          {columns?.includes(Keys.voltage_min) && (
            <td className="text-nowrap">{numberFormat(item.voltage_min, { fallback: '-' })}</td>
          )}
          {columns?.includes(Keys.voltage_max) && (
            <td className="text-nowrap">{numberFormat(item.voltage_max, { fallback: '-' })}</td>
          )}
          {columns?.includes(Keys.replacement_year) && <td>{item.replacement_year || '-'}</td>}
          {columns?.includes(Keys.max_capacity_future) && <td>{item.max_capacity_future || '-'}</td>}
          {columns?.includes(Keys.type_future) && <td>{item.type_future || '-'}</td>}
          {columns?.includes(Keys.replacement_reason) && <td>{item.replacement_reason || '-'}</td>}
          {columns?.includes(Keys.replacement_cost) && (
            <td>{numberFormat(item.replacement_cost, { minimumFractionDigits: 2, fallback: '-' })}</td>
          )}
          {columns?.includes(Keys.pof_sum) && <td>{numberFormat(item.pof_sum, { fallback: '-' })}</td>}
          {columns?.includes(Keys.ikss_ka_min) && <td>{item.ikss_ka_min ?? '-'}</td>}
          {columns?.includes(Keys.ikss_ka_max) && <td>{item.ikss_ka_max ?? '-'}</td>}
          {columns?.includes(Keys.skss_mva_min) && <td>{item.skss_mva_min ?? '-'}</td>}
          {columns?.includes(Keys.skss_mva_max) && <td>{item.skss_mva_max ?? '-'}</td>}
          {columns?.includes(Keys.sc_angle_from_degrees_min) && <td>{item.sc_angle_from_degrees_min ?? '-'}</td>}
          {columns?.includes(Keys.sc_angle_to_degrees_min) && <td>{item.sc_angle_to_degrees_min ?? '-'}</td>}
          {columns?.includes(Keys.sc_angle_from_degrees_max) && <td>{item.sc_angle_from_degrees_max ?? '-'}</td>}
          {columns?.includes(Keys.sc_angle_to_degrees_max) && <td>{item.sc_angle_to_degrees_max ?? '-'}</td>}
          {columns?.includes(Keys.skss_mva) && <td>{item.skss_mva ?? '-'}</td>}
          <td className="text-right">
            <Button
              dataMarker="actions_profile"
              tooltipKey="Profile"
              icon={<IconDetails />}
              data-id={item.id}
              onClick={handleSeeProfileBtnClick}
              size="small"
            />
          </td>
        </tr>
      ))}
    </DataTable>
  );
};

export default TableCables;
