import React from 'react';
import { useSelector } from 'react-redux';
import { simulationIdSelector } from 'modules/layouts/selectors';
import { PageAppExceptions } from 'pages';
import { AppErrorsTypes } from 'constants/index';

const RequiredSimulationCheck: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const simulationId = useSelector(simulationIdSelector);

  if (!simulationId) {
    return <PageAppExceptions appError={AppErrorsTypes.NO_SIMULATIONS} />;
  }

  return <>{children}</>;
};

export default RequiredSimulationCheck;
