import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checklistsSelector } from 'modules/tasks/selectors';
import { fetchTaskChecklistsAction } from 'modules/tasks';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';
import { NonAssetTaskAppActions } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: number | null;
  appAction: Type.TaskAppActions | null;
  assetCategoryCode: Type.AssetCategories | null;
}

const SelectTaskChecklist: React.FC<Props> = ({
  labelKey = 'Checklist',
  value,
  appAction,
  assetCategoryCode,
  ...props
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const checklists = useSelector(checklistsSelector);
  const isDisabled = useMemo(
    () => !appAction || (!assetCategoryCode && !NonAssetTaskAppActions.includes(appAction)),
    [appAction, assetCategoryCode]
  );

  useEffect(() => {
    if (isDisabled) return;
    setIsLoading(true);
    dispatch(fetchTaskChecklistsAction({ appAction, assetCategoryCode })).then(() => setIsLoading(false));
  }, [isDisabled, appAction, assetCategoryCode, dispatch]);

  const { selectValue, selectOptions } = useMemo(() => {
    return checklists.reduce(
      (acc: any, checklist: Tasks.Checklist) => {
        const item = { value: checklist.id, label: checklist.name };
        if (item.value === value) acc.selectValue = item;
        acc.selectOptions.push(item);
        return acc;
      },
      { selectValue: null, selectOptions: [] }
    );
  }, [checklists, value]);

  return (
    <FormReactSelect
      {...props}
      labelKey={labelKey}
      value={selectValue}
      options={selectOptions}
      isDisabled={isLoading || isDisabled || props.isDisabled}
      isLoading={isLoading}
      isClearable
    />
  );
};

export default SelectTaskChecklist;
