import React, { useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { portfolioIdSelector, scenarioIdSelector, simulationIdSelector } from 'modules/layouts/selectors';
import { scenariosYearsOptionsSelector, simulationVersionIdSelector } from 'modules/options/selectors';
import { applyScenarioYearsOptionsAction, fetchScenarioYearsOptionsAction } from 'modules/options';
import { FormReactSelect, FormReactSelectProps } from 'components/_common';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  labelKey?: string;
  value: number | null;
  setInitialValue?: boolean;
  isInCustomerRightsProfileModal?: boolean;
}

const SelectScenarioYears: React.FC<Props> = ({
  labelKey = 'Scenarios year',
  value,
  isInCustomerRightsProfileModal = false,
  setInitialValue,
  onChange,
  ...rest
}) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const scenariosYearsOptions = useSelector(scenariosYearsOptionsSelector);
  const portfolioId = useSelector(portfolioIdSelector);
  const scenarioId = useSelector(scenarioIdSelector);
  const simulationId = useSelector(simulationIdSelector);
  const versionId = useSelector(simulationVersionIdSelector);

  useEffect(() => {
    if (!portfolioId || !scenarioId || !simulationId || !versionId) return;
    if (scenariosYearsOptions) {
      dispatch(applyScenarioYearsOptionsAction(scenariosYearsOptions));
    } else {
      setIsLoading(true);
      dispatch(fetchScenarioYearsOptionsAction({ portfolioId, scenarioId, simulationId, versionId })).finally(() =>
        setIsLoading(false)
      );
    }
  }, [dispatch, portfolioId, scenarioId, simulationId, versionId, scenariosYearsOptions]);

  const { selectOptions, selectValue } = useMemo(
    () =>
      (scenariosYearsOptions || []).reduce(
        (
          acc: { selectOptions: Type.SelectOption<number>[]; selectValue: Type.SelectOption<number> | null },
          option
        ) => {
          if (isInCustomerRightsProfileModal && option.value > new Date().getFullYear()) return acc;
          if (value === option.value) acc.selectValue = option;
          acc.selectOptions.push(option);
          return acc;
        },
        { selectOptions: [], selectValue: null }
      ),
    [value, scenariosYearsOptions, isInCustomerRightsProfileModal]
  );

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={isLoading}
      labelKey={labelKey}
      options={selectOptions}
      value={selectValue}
      isSearchable={false}
      onChange={onChange}
      {...rest}
    />
  );
};

export default SelectScenarioYears;
