import Highcharts from 'highcharts';
import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import Chart, { getBaseOptions } from 'components/_charts/Chart';
import { conditionAssessmentChartDataSelector } from 'modules/maintenance/selectors';
import { fetchConditionAssessmentChartDataAction } from 'modules/maintenance/actions';

const ChartConditionAssessment: React.FC = () => {
  const { getIntl, numberFormat } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const chartData = useSelector(conditionAssessmentChartDataSelector);

  useEffect(() => {
    dispatch(fetchConditionAssessmentChartDataAction());
  }, [dispatch]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions(getIntl, chartData);
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        categories: chartData?.categories?.map(c => getIntl(c)),
      },
      tooltip: {
        formatter(this: Highcharts.TooltipFormatterContextObject) {
          return `${numberFormat(this.point.y)}%`;
        },
      },
      series: chartData?.series?.map(s => ({ ...s, name: getIntl(s.name || '') })) || [],
    };
  }, [chartData, getIntl, numberFormat]) as unknown as Highcharts.Options;

  return <Chart options={chartData ? options : null} dataMarker="chart_maintenance_condition_assessment" />;
};

export default ChartConditionAssessment;
