import React from 'react';
import { Switch, Route } from 'react-router';
import { PageAppExceptions } from 'pages';
import { Models, Manufacturers } from 'components/AssetModels';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import { Routes, PermissionsTypes } from 'constants/index';

const PageAssetModels: React.FC = () => (
  <PagePermissionsProvider permissionKey={PermissionsTypes.isAssetModelsEnabled}>
    <RequiredScenarioCheck>
      <RequiredSimulationCheck>
        <Switch>
          <Route exact path={Routes.AssetModels} component={Models} />
          <Route exact path={Routes.AssetModelsManufacturers} component={Manufacturers} />
          <Route path="*" component={PageAppExceptions} />
        </Switch>
      </RequiredSimulationCheck>
    </RequiredScenarioCheck>
  </PagePermissionsProvider>
);

export default PageAssetModels;
