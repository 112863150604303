import styled from 'styled-components';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocale } from 'hooks';
import { releaseNotificationModalVersionSelector } from 'modules/layouts/selectors';
import { setLayoutAction } from 'modules/layouts';
import { Modal } from 'components/_common';
import { currentAppReleaseVersionSelector } from 'modules/app/selectors';

const ModalReleaseNotification: React.FC = () => {
  const { getIntl } = useLocale();
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [show, setShow] = useState(false);
  const releaseNotificationModalVersion = useSelector(releaseNotificationModalVersionSelector);
  const currentAppReleaseVersion = useSelector(currentAppReleaseVersionSelector);
  const isShow = currentAppReleaseVersion > Number(releaseNotificationModalVersion);

  useEffect(() => {
    if (isShow) setShow(true);
  }, [isShow]);

  const handleCloseBtnClick = useCallback(() => {
    setShow(false);
    setTimeout(() => dispatch(setLayoutAction({ releaseNotificationModalVersion: currentAppReleaseVersion })), 500);
  }, [dispatch, currentAppReleaseVersion]);

  return (
    <Modal
      show={show}
      onHide={handleCloseBtnClick}
      title={
        <span>
          &#127881; {getIntl("What's new in version {{version}}", { version: process.env.REACT_APP_VERSION! })}
        </span>
      }
      cancelButtonProps={{ labelKey: 'Close', onClick: handleCloseBtnClick }}
    >
      <>
        <p>{getIntl('Discover the latest enhancements and features in our web app')}:</p>
        {/* <ReleaseNotesList>
          <figcaption>&#128640; {getIntl('Features')}:</figcaption>
          <ul>
            <li>"Data quality &gt; Customers connected" - Evolution plots.</li>
            <li>"Map &gt; Unified Asset View &gt; Evolution plot" - Cabinets supported for power and voltage.</li>
          </ul>
        </ReleaseNotesList> */}
        <ReleaseNotesList>
          <figcaption>&#128736; {getIntl('Improvements')}:</figcaption>
          <ul>
            <li>"All modules" - Restricted views for non-power flow portfolios.</li>
            <li>"Map" - New satellite images.</li>
            <li>"Map &gt; Unified Asset View" - Cabinets don't support losses tab info message.</li>
            <li>"Map &gt; Single Line Diagram" - Beta notification info message removed.</li>
            <li>"Maintenance & Planning &gt; Forms &gt; Instructions" - Ability to edit form fields.</li>
            <li>"Maintenance & Planning &gt; Create task form" - Plan name includes asset code postfixes.</li>
          </ul>
        </ReleaseNotesList>
        <ReleaseNotesList>
          <figcaption>&#128030; {getIntl('Fixes')}:</figcaption>
          <ul>
            <li>"Customers" - Ability to select simulations.</li>
            <li>"Network loading" - Portfolio and scenario endpoint parameters.</li>
            <li>"Data quality &gt; Meters" - Ability to download Excel file.</li>
            <li>
              "Network loading &gt; Timeseries" - Profile and duration curve take transformer power sign into account.
            </li>
            <li>"Maintenance & Planning &gt; Detailed plan" - Ability to click "Change asset in plan" form button.</li>
          </ul>
        </ReleaseNotesList>
        <p>{getIntl('Thank You for Your Collaboration')}! &#128578;</p>
        <p>
          {getIntl(
            'We appreciate your continued feedback, which help us improve and grow. Stay tuned for more exciting updates and features. Together, we’re making great things happen'
          )}
          !
        </p>
      </>
    </Modal>
  );
};

const ReleaseNotesList = styled.figure`
  margin-left: 20px;
  > ul {
    line-height: 2;
    margin-bottom: 0;
  }
`;

export default ModalReleaseNotification;
