import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, RouteComponentProps } from 'react-router';
import { isLiveDataConnectionSelector } from 'modules/app/selectors';
import { ViewHistoryDetails, ViewAnswerDetails, ViewChecklists, TableAnswers, TableHistory } from 'components/History';
import PagePermissionsProvider from './PagePermissionsProvider';
import RequiredScenarioCheck from './RequiredScenarioCheck';
import RequiredSimulationCheck from './RequiredSimulationCheck';
import PageAppExceptions from './PageAppExceptions';
import { Routes, PermissionsTypes } from 'constants/index';

const PageHistory: React.FC = () => {
  const isLiveDataConnection = useSelector(isLiveDataConnectionSelector);

  return (
    <PagePermissionsProvider permissionKey={PermissionsTypes.isHistoryEnabled}>
      <RequiredScenarioCheck>
        <RequiredSimulationCheck>
          <Switch>
            <Route
              exact
              path={Routes.HistoryAnswers}
              render={
                ((props: RouteComponentProps<{ id: string }>) => {
                  return props.match.params.id ? (
                    <ViewAnswerDetails {...props} />
                  ) : (
                    <TableAnswers containerClassName="border-top-0" maxHeight="calc(100vh - 165px)" />
                  );
                }) as any
              }
            />
            {!isLiveDataConnection && (
              <Route exact path={Routes.HistoryChecklists}>
                <ViewChecklists />
              </Route>
            )}
            <Route
              exact
              path={Routes.History}
              render={
                ((props: RouteComponentProps<{ id: string }>) => {
                  return props.match.params.id ? (
                    <ViewHistoryDetails {...props} />
                  ) : (
                    <TableHistory containerClassName="border-top-0" maxHeight="calc(100vh - 165px)" />
                  );
                }) as any
              }
            />
            <Route path="*" component={PageAppExceptions} />
          </Switch>
        </RequiredSimulationCheck>
      </RequiredScenarioCheck>
    </PagePermissionsProvider>
  );
};

export default PageHistory;
